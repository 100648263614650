import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faHome, faPhone} from "@fortawesome/free-solid-svg-icons";

export interface TeamMemberProps {
    name: string;
    description: string;
    jobDescription: string;
    imageUrl: string;
    email?: string;
    phone?: string;
    website?: string;
}

export const TeamMember = (props: TeamMemberProps) => (
    <div>
        <div className="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid" uk-grid="">
            <div className="uk-card-media-left uk-cover-container uk-first-column">
                <img src={props.imageUrl} alt="" uk-cover="" className="uk-cover"/>
                <canvas width="600" height="400"></canvas>
            </div>
            <div className="flex-content">
                <div className="uk-card-body">
                    <h3 className="uk-card-title">{props.name}</h3>
                    <label className="uk-text-muted uk-text-small">{props.jobDescription}</label>
                    <p>{props.description}</p>
                </div>
                <div className="uk-card-footer">
                    {props.email && <div><FontAwesomeIcon className="uk-margin-small-right" icon={faEnvelope}/>{props.email}</div>}
                    {props.phone && <div><FontAwesomeIcon className="uk-margin-small-right" icon={faPhone}/>{props.phone}</div>}
                    {props.website && <div><FontAwesomeIcon className="uk-margin-small-right" icon={faHome}/><a href={props.website} target="_blank">{props.website}</a></div>}
                </div>
            </div>
        </div>
    </div>
);
