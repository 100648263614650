import * as React from "react";
import { render } from "react-dom";
import App from "./components/app/app";
import DirectusSDK from "@directus/sdk-js";

declare global {
    interface Window {
        directus: DirectusSDK;
    }
}

window.directus = new DirectusSDK({
    url: "https://" + window.location.hostname + "/directus/",
    project: "praxisgemeinschaft-jedlesee",
    mode: "jwt",
});

const rootEl = document.getElementById("root");
render(<App />, rootEl);
