import * as React from "react";

import "./headerSection.scss";

interface HeaderSectionState {
    activeSection: string;
}

interface NavEntry {
    sectionId: string;
    title: string;
}

export default class HeaderSection extends React.Component<Record<string, unknown>,
    HeaderSectionState> {

    private navEntries: NavEntry[] = [
        {sectionId: "page-start", title: "Start"},
        {sectionId: "page-services", title: "Angebot"},
        {sectionId: "page-team", title: "Team"},
        {sectionId: "page-gallery", title: "Bilder"},
        {sectionId: "page-contact", title: "Kontakt"},
    ];

    constructor(props) {
        super(props);
        this.state = {
            activeSection: this.navEntries[0].sectionId,
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll.bind(this));
    }

    determineActiveSection(): string {
        for (let i = 0; i <= this.navEntries.length; i++) {
            const sectionId = this.navEntries[i].sectionId;
            const elem = document.getElementById(sectionId);
            if (elem && this.isInViewport(elem)) {
                return sectionId;
            }
        }
        return "";
    }

    isInViewport(elem) {
        const bounding = elem.getBoundingClientRect();
        const navbarHeight = 80;

        return (bounding.top - navbarHeight) <= 0 && -(bounding.top - navbarHeight) <= bounding.height;
    }

    handleScroll() {
        this.setState({
            activeSection: this.determineActiveSection(),
        });
    }

    public render() {
        return (
            <div id="page-header">
                <div
                    uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar">
                    <nav
                        className="uk-navbar-container"
                        uk-navbar={1}
                        style={{position: "relative", zIndex: 980}}
                    >

                        <div className="uk-navbar-left">
                            <a className="uk-navbar-item uk-logo" href="#">
                                Praxisgemeinschaft Jedlesee
                            </a>
                        </div>

                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav">
                                {this.navEntries.map(entry => (
                                    <li className={this.state.activeSection == entry.sectionId ? "uk-active" : ""}>
                                        <a href={"#" + entry.sectionId} uk-scroll="offset:50;">{entry.title}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}
