import * as React from "react";

import "./gallerySection.scss";
import {GalleryImage} from "./galleryImage";

export interface GalleryData {
    data: GalleryEntry[];
}

export interface GalleryEntry {
    caption: string;
    picture: {
        data: {
            full_url: string;
            thumbnails: DirectusThumbnail[];
        };
    };
}

interface DirectusThumbnail {
    key: string;
    url: string;
}


export default class GallerySection extends React.Component<GalleryData, {}> {

    public render() {
        return (
            <div id="page-gallery" className="uk-section uk-section-default">
                <h1 className="uk-text-center uk-margin-large-bottom">Unsere Bilder</h1>
                <div className="gallery" uk-lightbox="animation: slide">
                    {this.props.data.map((entry) =>
                        <GalleryImage caption={entry.caption} imageUrl={entry.picture.data.full_url} thumbnailUrl={entry.picture.data.thumbnails[3].url} />
                    )}
                </div>
            </div>
        );
    }
}
