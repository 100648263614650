import * as React from "react";

import "./startSection.scss";

export interface StartData {
    data: StartEntry[];
}

export interface StartEntry {
    picture: {
        data: {
            full_url: string;
        };
    };
}

export default class StartSection extends React.Component<StartData, {}> {
    constructor(props: undefined) {
        super(props);
    }

    public render() {
        return (
            <div id="page-start">
                <div
                    className="uk-position-relative uk-visible-toggle uk-light"
                    tabIndex={-1}
                    uk-slideshow="animation: push; max-height: 600; autoplay: true; autoplay-interval: 6000;"
                >
                    <div className="slideshow-container">
                        <ul className="uk-slideshow-items">
                            {this.props.data.map((entry) => (
                                <li>
                                    <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">
                                        <img
                                            src={entry.picture.data.full_url}
                                            alt=""
                                            uk-cover={1}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="slideshow-mask"></div>
                    </div>

                    <a
                        className="uk-position-center-left uk-position-small uk-hidden-hover"
                        href="#"
                        uk-slidenav-previous={1}
                        uk-slideshow-item="previous"
                    ></a>
                    <a
                        className="uk-position-center-right uk-position-small uk-hidden-hover"
                        href="#"
                        uk-slidenav-next={1}
                        uk-slideshow-item="next"
                    ></a>
                </div>
            </div>
        );
    }
}
