import * as React from "react";

export interface GalleryImageProps {
    caption: string;
    imageUrl: string;
    thumbnailUrl: string;
}

export const GalleryImage = (props: GalleryImageProps) => (
    <div className="gallery-image">
        <a className="uk-inline" href={props.imageUrl} data-caption={props.caption}>
            <img src={props.thumbnailUrl} alt={props.caption}/>
        </a>
    </div>
);
