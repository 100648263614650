import * as React from "react";

import "./servicesSection.scss";
import {doc} from "prettier";
import trim = doc.builders.trim;

export interface ServiceData {
    entries: ServiceEntry[];
    tags: ServiceTags;
}

export interface ServiceTags {
    tags: string;
}

export interface ServiceEntry {
    title: string;
    text: string;
    picture: {
        data: {
            full_url: string;
        };
    };
}

export default class ServicesSection extends React.Component<ServiceData, {}> {
    public render() {
        const tags = this.props.tags.tags.split(",");
        return (
            <div id="page-services" className="uk-section uk-section-default">
                <div className="uk-container">
                    <h1 className="uk-text-center">Unser Angebot</h1>
                    <h2 className="uk-text-center uk-margin-remove-bottom">
                        {tags.map((tag) => (
                            <span className="badge">{tag.trim()}</span>
                        ))}
                    </h2>
                    <br />
                    <br />
                    {this.props.entries.map((entry, i) => (
                        <div className="uk-child-width-expand@s uk-grid" uk-grid={1}>
                            {i % 2 == 0 && (
                                <div>
                                    <img src={entry.picture.data.full_url} />
                                </div>
                            )}
                            <div>
                                <h3>{entry.title}</h3>
                                <p>{entry.text}</p>
                            </div>
                            {i % 2 != 0 && (
                                <div>
                                    <img src={entry.picture.data.full_url} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
