import * as React from "react";

import "./contactSection.scss";


export interface ContactEntry {
    address: string;
    address_description: string;
    email: string;
    email_description: string;
}

export interface ContactData {
    data: ContactEntry;
}

export default class ContactSection extends React.Component<ContactData, {}> {
    public render() {
        return (
            <div id="page-contact" className="uk-section uk-section-default">
                <div className="uk-container">
                    <h1 className="uk-text-center uk-margin-large-bottom">
                        Wir freuen uns auf Sie
                    </h1>

                    <div
                        className="uk-grid uk-child-width-1-1 uk-child-width-1-2@s"
                        uk-grid={1}
                    >
                        <div className="info-container">
                            <div className="address">
                                <h1 dangerouslySetInnerHTML={{__html: this.props.data.address}}></h1>
                                <div className="description" dangerouslySetInnerHTML={{__html: this.props.data.address_description}}></div>
                            </div>
                            <div className="contact">
                                <h2>{this.props.data.email}</h2>
                                <p dangerouslySetInnerHTML={{__html: this.props.data.email_description}}></p>
                            </div>
                        </div>
                        <div>
                            <iframe
                                className="picture-frame uk-box-shadow-large"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2655.8455476506756!2d16.385588251527025!3d48.26734677913202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d067505fd66a5%3A0xcf368bf9a7d1dc0d!2sSchillgasse%2025%2C%201210%20Wien!5e0!3m2!1sen!2sat!4v1601022786333!5m2!1sen!2sat"
                                height={450}
                                frameBorder={0}
                                allowFullScreen={true}
                                aria-hidden={false}
                                tabIndex={0}
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
