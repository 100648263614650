import * as React from "react";

import "./footerSection.scss"

export interface FooterEntry {
    owner: string;
    owner_contact: string;
}

export interface FooterData {
    data: FooterEntry;
}

export default class FooterSection extends React.Component<FooterData, {}> {
    public render() {
        return (
            <div id="page-footer" className="uk-section uk-section-default">
                <div className="uk-container">
                    <h4>Impressum / <small>Für den Inhalt verantwortlich</small></h4>
                    <div className="uk-child-width-expand@s uk-grid" uk-grid="">
                        <div className="uk-first-column" dangerouslySetInnerHTML={{__html: this.props.data.owner}}></div>
                        <div dangerouslySetInnerHTML={{__html: this.props.data.owner_contact}}></div>
                    </div>

                </div>
            </div>
        );
    }
}
