import * as React from "react";
import { hot } from "react-hot-loader";

import "../../../node_modules/uikit/dist/css/uikit.min.css";
import "../../../node_modules/uikit/dist/js/uikit.min.js";
import "../../../node_modules/uikit/dist/js/uikit-icons.min.js";

import "./app.scss";
import ServicesSection, {
    ServiceData,
    ServiceEntry,
    ServiceTags,
} from "../services/servicesSection";
import StartSection, { StartData, StartEntry } from "../start/startSection";
import HeaderSection from "../header/headerSection";
import TeamSection, { TeamData, TeamEntry } from "../team/teamSection";
import ContactSection, {
    ContactData,
    ContactEntry,
} from "../contact/contactSection";
import GallerySection, {
    GalleryData,
    GalleryEntry,
} from "../gallery/gallerySection";
import FooterSection, {
    FooterData,
    FooterEntry,
} from "../footer/footerSection";

interface AppState {
    startData?: StartData;
    serviceData?: ServiceData;
    teamData?: TeamData;
    galleryData?: GalleryData;
    contactData?: ContactData;
    footerData?: FooterData;
}

class App extends React.Component<Record<string, unknown>, AppState> {
    constructor(props) {
        super(props);

        this.state = {
            startData: null,
            serviceData: null,
            teamData: null,
            galleryData: null,
            contactData: null,
            footerData: null,
        };

        window.directus
            .getItems<StartEntry[]>("start", {
                fields: "picture.data.full_url",
            })
            .then((response) => {
                this.setState({
                    startData: response,
                });
            });

        Promise.all([
            window.directus.getItems<ServiceEntry[]>("angebot", {
                fields: "*,picture.data.full_url",
                filter: {
                    status: {
                        eq: "published",
                    },
                },
            }),
            window.directus.getItems<ServiceTags[]>("angebot_stichworte"),
        ]).then((responses) => {
            this.setState({
                serviceData: {
                    entries: responses[0].data,
                    tags: responses[1].data[0],
                },
            });
        });

        window.directus
            .getItems<TeamEntry[]>("team", {
                fields: "*,picture.data.full_url",
                filter: {
                    status: {
                        eq: "published",
                    },
                },
            })
            .then((response) => {
                this.setState({
                    teamData: response,
                });
            });

        window.directus
            .getItems<GalleryEntry[]>("bilder", {
                fields: "*,picture.data.*",
            })
            .then((response) => {
                this.setState({
                    galleryData: response,
                });
            });

        window.directus.getItems<ContactEntry[]>("kontakt").then((response) => {
            this.setState({
                contactData: { data: response.data[0] },
            });
        });

        window.directus
            .getItems<FooterEntry[]>("impressum")
            .then((response) => {
                this.setState({
                    footerData: { data: response.data[0] },
                });
            });
    }

    public render() {
        const data = this.state;
        const firstSectionLoaded = !!data.startData;

        return (
            <div className="app">
                <HeaderSection />
                {data.startData && <StartSection {...data.startData} />}
                {firstSectionLoaded && data.serviceData && (
                    <ServicesSection {...data.serviceData} />
                )}
                {firstSectionLoaded && data.teamData && (
                    <TeamSection {...data.teamData} />
                )}
                {firstSectionLoaded && data.galleryData && (
                    <GallerySection {...data.galleryData} />
                )}
                {firstSectionLoaded && data.contactData && (
                    <ContactSection {...data.contactData} />
                )}
                {firstSectionLoaded && data.footerData && (
                    <FooterSection {...data.footerData} />
                )}
            </div>
        );
    }
}

declare let module: Record<string, unknown>;

export default hot(module)(App);
