import * as React from "react";

import "./teamSection.scss";
import { TeamMember } from "./teamMember";

export interface TeamEntry {
    created_on: string;
    description: string;
    email: string;
    id: number;
    modified_by: number;
    modified_on: number;
    name: string;
    phone: string;
    picture: {
        data: {
            full_url: string;
        };
    };
    qualification: string;
    sort: number;
    status: string;
    website: string;
}

export interface TeamData {
    data: TeamEntry[];
}

export default class TeamSection extends React.Component<TeamData, {}> {
    constructor(props: undefined) {
        super(props);
    }

    public render() {

        return (
            <div id="page-team" className="uk-section uk-section-default">
                <div className="uk-container uk-container-xlarge">
                    <h1 className="uk-text-center uk-margin-large-bottom">
                        Unser Team
                    </h1>
                    <div
                        className={
                            "uk-child-width-1-2@m uk-grid uk-grid-match"
                        }
                        uk-grid=""
                        uk-scrollspy="cls: uk-animation-slide-bottom; target: .uk-card; delay: 300; repeat: false"
                    >
                        {this.props.data.map((entry) => (
                            <TeamMember
                                name={entry.name}
                                description={entry.description}
                                jobDescription={entry.qualification}
                                imageUrl={entry.picture.data.full_url}
                                phone={entry.phone}
                                email={entry.email}
                                website={entry.website}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
